export const routes = {
  home: '/',
  marketingHome: '/marketing',
  tech: '/tech',
  homeAbout: '/about',
  homeContact: '/contact',
  mindMap: '/mind-map',
  register: '/register',
  editMindmap: '/mindmap/edit/:id',
  sharedLinkMindmap: '/workspace/:id',
  login: '/login',
  forgotPassword: '/forgotPassword',
  dashBoard: '/workspaces',
  myAccount: '/my-account',
  resetPassword: '/reset-password',
  stripeDemo: '/stripe-demo',
  stripeEmbedDemo: '/buy-subscription',
  currentWorkSpace: '/CurrentWorkSpace',
  paymentSuccess: '/payment-success/:checkout_session',
  paymentSuccessUi: '/payment-success-ui/:checkout_session',
  paymentFail: '/payment-fail',
  pricing: '/pricing',
  test: '/test',
  activate_subscription: '/subscription/:sub_id',
  termsAndCondition: '/terms-condition',
  privacyPolicy: '/privacy-policy',
  generateLink: '/generate-link',
  referral: '/referral/:code',
  addSubscriptionDay: '/add-subscription-day',
  companyProfile: '/company-profile',
  companyProfileEdit: '/company-profile/:id/edit',
  workspaceEdit: '/workspace/:id/edit',
};
